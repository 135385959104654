.app__testimonial{
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start !important;
    padding-top: 3.5rem;

    h2{
        margin-bottom: 2rem;
    }
}

.app__testimonialSize{
    min-height: auto !important;
}

.app__testimonial-item{
    width: auto;
    max-width: 90%;
    min-height: 150px;
    max-height: 200px;

    background-color: var(--secondary-color);
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
    display: flex;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    transition: all 0.3s ease-in-out;

    img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: var(--primary-color);
        object-fit: cover;
    }

    @media screen and (min-width: 2600px) {
        img{
            width: 150px;
            height: 150px;
        }
    }

    @media screen and (max-width: 850px) {
        width: 100%;

    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        max-height: fit-content;
        padding: 1rem;
        .app__testimonial-content{
            height: fit-content;
            h4{
                margin-top: 1rem;
            }
        }
    }
}

.app__testimonial-content{
    flex: 1;
    height: 100%;

    padding: 0 2rem;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    p{
        font-size: 1.1rem;
        line-height: 2rem;   
        color: var(--text-color);
        font-family: var(--font-base);
    }

    h4{
        font-weight: 600;
        font-size: 1.2rem;
        color: var(--secondary-text-color);
        margin-top: 0;
    }

    h5{
        font-weight: 400;
        font-size: 0.9rem;
        color: var(--gray-text-color);
        margin-top: 5px;
    }
}

.app__testimonial-btns{
    flex-direction: row;
    margin-top: 1rem;

    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--secondary-color);

        margin: 1rem;
        transition: all 0.3s ease-in-out;

        svg{
            width: 20px;
            height: 20px;
            color: var(--text-color)
        }

        &:hover{
            background-color: var(--hovering-color);

            svg{
                color: var(--gray-text-color);
            }
        }

        cursor: pointer;
    }
}

.app__testimonial-brands{
    width: 80%;
    flex-wrap: wrap;
    margin-top: 1.25rem;

    div{
        width: 150px;
        margin: 1.5rem;

        img{
            width: 100%;
            height: auto;
            object-fit: cover;
            filter: grayscale(1);
        }

        &:hover{
            img{
                filter: grayscale(0);
            }
        }

        @media screen and (min-width: 2600px) {
            width: 210px;
            margin: 2rem;
        }

        @media screen and (max-width: 450px) {
            width: 120px;
            margin: 1rem;
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}