.app__navbar{
    z-index: 1000;
    width: 100%;
    min-height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem;
    background-color: var(--secondary-color);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed; 
}

.app__navbar-logo{
    display: flex;
    justify-content: flex;
    align-items: center;
    position:absolute;

    img{
        width: 150px;
        height: auto;

        @media screen and (min-width: 2600px) {
            width: 90px;
            height: 40px;
        }
    }
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li{
        margin: 0px 1rem;
        cursor: pointer;

        flex-direction: column;

        div{
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;

            margin-bottom: 5px;
        }

        a{
            font-size: 1.25rem;
            color: var(--white-color);
            text-decoration: none;
            flex-direction: column;

            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;

            &:hover{
                color: var(--span-color);
            }
        }

        &:hover{
            div{
                background: var(--span-color)
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu{
    width: 35px;
    height: 35px;
    position: relative;
    position: absolute;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);

    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    div{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;

        z-index: 5;

        width: 100%;
        height: fit-content;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: black;

        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg{
            width: 35px;
            height: 35px;
            color: var(--text-color);
            margin: 0.5rem 1rem;
            margin-top: 1rem;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li{

                border-bottom: rgba(255, 255, 255, 0.2) solid 3px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                a{
                    height: 100%;
                    color: var(--text-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin: 1rem 0;
                    margin-left: 1rem;

                    &:hover{
                        color: var(--span-color);
                    }
                }
            }
        }

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

    
    @media screen and (min-width: 900px) {
        display: none;
    }
}