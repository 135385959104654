.app__skills{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__skillsSize{
    min-height: 100vh !important;
    height: fit-content !important;
    padding: 2rem 0;
}

.app__skills-container{
    width: 80%;
    margin-top: 3rem;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px){
        width: 100%;
        flex-direction: column;
    }
}

.app__skills-list-container {
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start; /* This can remain as is, or you can change it to center if you want vertical centering */
    flex-direction: row;

    h2 {
        color: var(--text-color);
        font-size: 1.7rem;
        width: 100%;
        text-align: left; /* You might want to keep this for your title */
        height: auto;
        margin-bottom: 1rem;
    }
}

.app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Change this to 'center' to center items horizontally */
    align-items: center; /* Center items vertically within the container */
    width: 100%;
    margin-right: 0;

    @media screen and (max-width: 900px) {
        margin-right: 0;
        justify-content: center; /* Keep it centered for smaller screens */
        align-items: center; /* Center items vertically */
    }
}

.app__skills-item{
    flex-direction: column;
    text-align: center;
    width: 100px;
    margin: 1rem;
    transition: all 0.3s ease-in-out;

    div{
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: var(--secondary-color);

        img{
            width: 50%;
            height: 50%;
            object-fit: contain;
        }

        &:hover{
            box-shadow: 0 0 25px #fef4f5;
        }

        @media screen and (min-width: 2600px){
            width: 150px;
            height: 150px;
        }
    }

    p{
      font-weight: 500;  
      margin-top: 0.5rem;
    }

    @media screen and (min-width: 2600px){
        margin: 1rem 2rem;

        p{
            margin-top: 1rem;
        }
    }
}

.app__circular_progress{
    z-index: 1;
    position:absolute;
    width: 80px !important;
    height: 80px !important;
    opacity: 1;
    background: transparent !important;
    border-width: 20px;
}

.app__skills-exp{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;

    @media screen and (max-width: 900px){
        margin-top: 2rem;
    }

    @media screen and (max-width: 550px){
        margin-right: 1.25rem;
        margin-left: 1.25rem;
    }
}

.app__skills-exp-item{
    width: 100%;
    background: var(--secondary-color);
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
    box-shadow: 0 0 20px var(--secondary-color);
}

.app__skills_right_container{
    flex: 1;
    margin-right: 1rem;

    .app__skills-exp-work{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0;

        h4{
            color: var(--text-color);
            font-weight: 500;
        }

        p{
            word-wrap: break-word;
            font-weight: 400;
            margin-top: 5px;
        }

        .company-text{
            color: var(--gray-text-color);
            font-size: 0.85rem;
        }

        .date-text{
            color: var(--gray-text-color);
            font-size: 0.7rem;
        }

        .desc-text{
            display: inline-block;
            white-space: pre-line;
        }

        .tools-text{
            color: var(--gray-text-color);
        }
    }
}

.app__skills_left_container{
    margin-right: 1rem;
    margin-left: 1rem;
    height: fit-content;
    width: fit-content;

    img{
        width: 50px;
        height: 50px;
        z-index: 3;
        border-radius: 5px;
    }

    p{
        color: var(--text-color);
        font-weight: 800;
    }

    @media screen and (max-width: 450px){
        margin-right: 1rem;
    }
}

.skills-tooltip{
    max-width: 300px !important;
    width: fit-content !important;
    height: fit-content !important;
    background-color: var(--secondary-color) !important;
    border-radius: 15px !important;
    padding: 1rem !important;
    color: var(--text-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;

    @media screen and (min-width: 2600px) {
        font-size: 1.75rem !important;
        max-width: 500px !important;
        line-height: 2 !important;
    }
}