span{
    color:var(--span-color);
}

.buttonsContainers{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5rem;
    
    .button-item{
        background: var(--secondary-color);
        border-radius: 50%;
        margin: 0 1rem;
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            margin-top: 0.5rem;
            font-size: 2rem;
            text-align: center;
        }
    }
}

.video-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;

    h1{
        color:var(--text-color);
        width: 85%;
        margin-bottom: 1rem;
        text-align: center;
        font-style: oblique;
        text-transform: capitalize;
    }

    .react-player {
        height: 77vh !important;
        width: 85% !important;
    }
}

.picture-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: fit-content;
    h1{
        color:var(--text-color);
        width: 85%;
        margin-bottom: 1rem;
        text-align: center;
        font-style: oblique;
        text-transform: capitalize;
    }
}

.background{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: fit-content;
    top: 5px;
    background: var(--second-background);
    z-index: -1;
}

.navbar-gap{
    margin-top: 5rem;
}

.project-info-container{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    justify-content: center;
}

.project-info{
    border-radius: 20px;
    background: var(--secondary-color);
    margin: 0 0;
    width: 80%;

    .title{
        text-align: left;
        color: var(--span-color);
    }

    h1{
        margin-left: 1.5rem;
        margin-top: 1rem;
        color: var(--text-color);
        text-align: center;
        width: 90%;
        font-weight: 1000;
        font-size: 2.75rem;
    }

    .tags-container{
        height: fit-content;
        margin: 1rem;
        margin-top: 0.2rem;
        margin-left: 1.5rem;
    }

    .details-container{
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 450px) {
            flex-direction: column;
            align-items: center;
            padding: 1rem;
        }

        .description{
            flex: 2;

            margin-right: 0.7rem;
            margin-top: 0.3rem;

            h1{
                font-size: 2rem;
                margin-top: 0.5rem;
                margin-left: 0;
                width: 100%;
                text-align: left
            }

            p{
                margin-left: 0;
                margin-top: 0.7rem;
                margin-bottom: 0.7rem;
                color: var(--secondary-text-color);
                width: 100%;
                font-weight: 500;
                display: inline-block;
                white-space: pre-line;
                line-height: 1rem;
                text-align: left;
            }
        }

        .image-banner{
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 300px;
            object-fit: cover;
            margin: 1rem 1rem;
            
            img {
                border-radius: 20px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.skills_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }

    h2{
        color: var(--text-color);
    }

    .info-container{
        background:var(--primary-color);
        border-radius: 10px;
        opacity: 0.95;
        padding: 1rem 1.5rem;
        margin: 1rem;
        height: fit-content;
        width: fit-content;
        min-width: 235px;
    }
}

.detail-boxes{
    width: 100%;
    height: fit-content;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;

    .detail-item{
        background: var(--secondary-color);
        width: 195px;
        height: 195px;
        margin: 1rem 1rem;
        border-radius: 10px;
        &:hover{
            box-shadow: 0 0 25px #fef4f5;
        }

        .detail-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60%;
            margin-top: 1rem;

            h1{
                color: var(--text-color);
                text-align: center;
                font-size: 4rem;
            }
        }
        p{
            height: 20%;
            margin: 0 1rem;
            margin-bottom: 0.5rem;
            text-align: center;
        }
    }
}

.skills-container{
    background: var(--secondary-color) !important;
    align-items: center;
}

.white-skill{
    background-color: transparent !important;
}