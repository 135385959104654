.mainContainer{
    width: 100vw;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    flex-direction: column;
    background-color: var(--background-secondary);
    text-decoration: none;
    text-align: center;

    h1{
        font-size: 20rem;
    }

    h2{
        font-weight: 600;
        font-size: 2.5rem;
    }
}