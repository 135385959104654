@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --first-background: #232323;
  --second-background: #181818;

  --background-primary: #111111;
  --background-secondary: #000000;

  --primary-color: #20232a;
  --secondary-color: #050505;
  --black-color: #131516;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #e8e6e3;

  --highlight-color: #fef4f5;
  --hovering-color: #ECB365;

  --span-color: #ECB365;
  --text-color: #e4e4e4;
  --secondary-text-color: #e4e4e4;
  --gray-text-color: #6b7688;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}