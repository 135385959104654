h2{
    margin-top: 0;
}

h5{
    margin-top: 3rem;
}

.red_text{
    color:#D2001A !important;
    text-transform: lowercase;
}

.green_text{
    color:#367E18 !important;
    text-transform: capitalize;
}

.app__about{
    flex: 1;
    width: 100%;
    min-height: 100px !important;
    height: fit-content !important;
    flex-direction: column;

    h2{
        margin-top: 1.5rem;
    }
}

.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.emoji{
    height: 2.5rem;
}

.app__profile-item{
    background: var(--secondary-color);
    border-radius: 15px;
    width: 225px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-color: var(--secondary-color);
    border-style:solid;
    border-width: 6px;

    img{
        width: 100%;
        height: 185px;
        border-radius: 0;
        object-fit: cover;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
    }

    h2{
        color:var(--span-color);
        margin: 0 5%;
        margin-bottom: 5%;
    }

    p{
        color:var(--lightGray-color);
        margin: 0 5%;
        margin-bottom: 5%;
    }

    @media screen and (min-width: 2600px){
        width: 370px;
        margin: 2rem 4rem;

        img{
            height: 320px;
        }
    }
}