.app__works{
    flex: 1;
    width: 100%;
    min-height: 100vh !important;
    height: fit-content !important;
    height: auto !important;
    flex-direction: column;
    justify-content: flex-start !important;

    h2{
        margin-top: 1.5rem;
    }
}

.app__work-filter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 65%;

    margin: 4rem 0 0rem;
    margin-top: 0rem;

    .app__work-filter-item{
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: var(--secondary-color);
        color: var(--lightGray-color);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover{
            box-shadow: 0 0 25px var(--highlight-color);
            color: var(--lightGray-color);
        }

        @media screen and (min-width: 2600px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active{
        background-color: var(--span-color);
        p{
            color:black;
        }
    }
}

.app__work-portfolio{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item{
        width: 420px;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: var(--secondary-color);
        color: #000;

        cursor: pointer;
        transition: all 0.3s ease;

        &:hover{
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2)
        }

        @media screen and (min-width: 2600px){
            width: 570px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and(max-width: 600px){
            width: 250px;
        }

        @media screen and (min-width: 300px){
            margin: 1rem;
        }
    }
}

.app__work-img{
    width: 100%;
    height: 250px;

    position: relative;

    img{
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        background-color: var(--primary-color);
    }

    @media screen and (min-width: 2600px){
        height: 350px;
    }
}

.app__work-hover{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;

        margin: 1rem;
        font-family: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg{
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
}

.app__work-content{
    padding: 0, 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4{
        color: var(--span-color);
        font-size: 1.5rem;
        margin-top: 0.5rem;
        line-height: 1.5;
    }

    .app__work-tag{
        margin-top: 1rem;
        text-align: left;
        width: 100%;

        p{
            width: 100%;
            color: var(--lightGray-color);
        }
    }

    .icons-container{
        width: 100%;
        height: fit-content;
        position: absolute;
        top: -270px;
    }

    .icons-container-tech{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: fit-content;
        position: absolute;
        top: -270px;
    }

    .app__work-languages{
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        background-color: var(--secondary-color);
        height: 40px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: fit-content;
        padding: 0.5rem 0.3rem;

        img{
            height: 100%;
            margin: 0 0.3rem;
        }
    }

    .app__work-techs{
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        border-bottom-right-radius: 0;
        background-color: var(--secondary-color);
        height: 40px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: fit-content;
        padding: 0.5rem 0.3rem;

        img{
            height: 100%;
            margin: 0 0.3rem;
        }
    }
}