#tsparticles{
    background: transparent;// linear-gradient(var(--background-primary), var(--background-secondary));
    height: 100vh;
    width: 100%;
    margin: 0px 0px;
    position: fixed;
    overflow: hidden;
    z-index: 0;
}

.overlap{
    z-index: 3;
}

#background{
    background: transparent;// linear-gradient(var(--background-primary), var(--background-secondary));
    height: 100vh;
    width: 100%;
    margin: 0px 0px;
    position: fixed;
    overflow: hidden;
    z-index: 1;

    #tsparticles{
        opacity: 1 !important;
    }

    .test{
        width: 50% !important;
    }
}

.test{
    width: 50%;
    height: 50%;
}