.fullscreen-image{
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .background{
        background: black;
        z-index: 9;
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }

    .image-container{
        background:transparent;
        width: 85%;
        max-height: 100vh;
        z-index: 10;
        img{
            width: 100%;
            max-height: 85vh;
            z-index: 11;
            object-fit: contain;
        }
        
        p{
            position: relative;
            bottom: 2rem;
            right: 0;
        }
    }

}